/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
import { AddNewDeliveryFolderDialogController } from './dialogs/addNewDeliveryFolderDialog-controller';
import { AuthConstant } from '../../../constants/auth.constant';

(function () {
  'use strict';

  angular.module('comcast.settings.xferclients').controller('XferclientDetailController', ['$scope', '$rootScope', '$state', '$stateParams', '$mdDialog', '$q', '$timeout', '$window', 'addelivery.event.constants', 'NotificationService', 'EnumService', 'loginService', 'XferClientResourceFactory', function ($scope, $rootScope, $state, $stateParams, $mdDialog, $q, $timeout, $window, EventConstants, NotificationService, enumService, loginService, XferClientResourceFactory) {
    /* PRIVATE : DATA */
    //Declare all private variables here
    var authEnums = new AuthConstant();
    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/

    var vm = this;
    /* BINDABLE : DATA */

    vm.xferClient = {};
    vm.originalXferClient = {};
    vm.transferPeriods = [{
      label: '1 hour',
      data: 3600
    }, {
      label: '2 hours',
      data: 7200
    }, {
      label: '3 hours',
      data: 10800
    }, {
      label: '6 hours',
      data: 21600
    }, {
      label: '12 hours',
      data: 43200
    }];
    vm.transferIntervals = [{
      label: 'minute',
      data: 60
    }, {
      label: '5 minutes',
      data: 300
    }, {
      label: '10 minutes',
      data: 600
    }, {
      label: '15 minutes',
      data: 900
    }];
    vm.statusArray = [{
      status: 'offline',
      label: 'Offline'
    }, {
      status: 'online',
      label: 'Online'
    }, {
      status: 'notregistered',
      label: 'Not Registered'
    }, {
      status: 'noconfig',
      label: 'No Config'
    }];
    vm.sessionData = loginService.getSessionData();
    vm.selectedDeliveryFolders = [];
    vm.searchDeliveryFolders = {};
    vm.xferclientDetailForm = null; // if you change what these strings say you may accidentally mess up the download URL

    vm.appVersion = navigator.appVersion.indexOf('Win') !== -1 ? 'Windows' : navigator.appVersion.indexOf('Mac') !== -1 ? 'MacOS' : '';
    vm.labelStatus = labelStatus;
    vm.displayTime = displayTime;
    vm.downloadInstaller = downloadInstaller;
    vm.selectDeliveryFolder = selectDeliveryFolder;
    vm.navToDestination = navToDestination;
    vm.addDeliveryFolder = addDeliveryFolder;
    vm.removeDeliveryFolder = removeDeliveryFolder;
    vm.exportDeliveryFolders = exportDeliveryFolders;
    vm.reset = reset;
    vm.save = save;
    vm.disableSave = disableSave;
    /* BINDABLE : METHODS*/

    function labelStatus(statusString) {
      for (var i = 0; i < vm.statusArray.length; i++) {
        if (vm.statusArray[i].status === statusString) {
          return vm.statusArray[i].label;
        }
      }

      return '';
    }

    function displayTime(time) {
      return time ? moment.tz(time, moment.tz.guess()).format('MM/DD/YYYY h:mm A z') : '';
    }

    function downloadInstaller() {
      var os = vm.appVersion.toLowerCase();
      var accountType = loginService.accountType().replace(/_/g, '');
      var type = accountType === authEnums.accountTypes.provider || accountType === 'PRODUCTIONSERVICESVENDOR' && vm.xferClient.xferVector === 'UPLOAD' ? 'provider' : accountType === authEnums.accountTypes.receiver || accountType === 'QCVENDOR' || accountType === 'PRODUCTIONSERVICESVENDOR' && vm.xferClient.xferVector === 'DOWNLOAD' ? 'receiver' : '';
      XferClientResourceFactory.getDownloadLink({
        os: os,
        type: type
      }, {}, function success(response) {
        var downloadLink = document.createElement('A');
        downloadLink.href = response.data;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }, function failure(err) {
        console.log(err);
      });
    }

    function selectDeliveryFolder(index, event, selectable) {
      if (!selectable) {
        // This is a default row that cannot be selected
        return;
      }

      if (!event.ctrlKey && !event.metaKey && !event.shiftKey) {
        vm.selectedDeliveryFolders = [];
      }

      if (event.shiftKey && vm.lastSelection > -1) {
        // Do multiselect
        var toIndex = Math.max(index, vm.lastSelection);
        var fromIndex = Math.min(index, vm.lastSelection);

        for (var i = fromIndex; i <= toIndex; i++) {
          if (vm.selectedDeliveryFolders.indexOf(i) < 0) {
            vm.selectedDeliveryFolders.push(i);
          }
        }
      } else if (vm.selectedDeliveryFolders.indexOf(index) < 0) {
        vm.selectedDeliveryFolders.push(index);
      } else {
        vm.selectedDeliveryFolders.splice(vm.selectedDeliveryFolders.indexOf(index), 1);
      }

      vm.lastSelection = index;
    }

    function navToDestination(destinationId) {
      angular.element(document.querySelector('.sidebar')).addClass('invisible');
      $state.go('destinationDetails', {
        id: destinationId
      }).then(function () {
        // Apply the animation ONLY when this transition happens
        $timeout(function () {
          angular.element(document.querySelector('.sidebar')).removeClass('invisible');
          angular.element(document.querySelector('.destination-detail-list-area .destination-grid')).addClass('slide-left-transition');
        }, 0);
      });
    }

    function addDeliveryFolder() {
      $mdDialog.show({
        controller: AddNewDeliveryFolderDialogController,
        controllerAs: 'vm',
        template: require('./dialogs/addNewDeliveryFolderDialog-template.html'),
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        fullscreen: true,
        // For small screens only
        locals: {}
      }).then(function submitted(newFolder) {
        // Create the new folder on the xferClientDetails object
        vm.xferClient.xferDetails.pathMapping.push({
          destination: {
            id: newFolder.destination.id,
            alias: newFolder.destination.alias
          },
          type: newFolder.fileType,
          AUDIO: '',
          SD: '',
          HD: '',
          xferType: 'download'
        });
        $scope.xferclientDetailForm.$setDirty();
      }, function cancelled() {// Do nothing, the user cancelled
      });
    }

    function removeDeliveryFolder() {
      // If you don't remove them in order, bad things happen
      vm.selectedDeliveryFolders.sort(function (a, b) {
        return b - a;
      }); // Sorting pathmapping array as we are sorting in UI

      vm.xferClient.xferDetails.pathMapping.sort(function (a, b) {
        if (a.destination.alias > b.destination.alias) {
          return 1;
        } else if (a.destination.alias === b.destination.alias) {
          if (a.type > b.type) {
            return 1;
          } else {
            return -1;
          }
        } else {
          return -1;
        }
      });

      for (var i = 0; i < vm.selectedDeliveryFolders.length; i++) {
        vm.xferClient.xferDetails.pathMapping.splice(vm.selectedDeliveryFolders[i], 1);
      } // Reset the selections, because we removed them, and don't want to select the next ones


      vm.selectedDeliveryFolders = []; // Dirty the form, so the user can save

      $scope.xferclientDetailForm.$setDirty();
    }

    function exportDeliveryFolders() {
      // Download the export
      $window.open(vm.exportUrl + '?authorization=' + loginService.getJwt());
    }
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here


    _init();
    /* IMPLEMENTATION : BINDABLE */


    function disableSave() {
      // If the form has not been modified (whether new or existing) prevent unnecessary saves
      if (!vm.xferclientDetailForm || vm.xferclientDetailForm !== undefined && !vm.xferclientDetailForm.$dirty) {
        return true;
      }
    }

    function reset() {
      vm.xferClient = angular.copy(vm.originalXferClient); // $scope.xferclientDetailForm.$setPristine();

      vm.xferclientDetailForm.$setPristine();
      $rootScope.xferclientDetailForm.$setPristine();
    }

    function save() {
      // If there is path mapping, validate that it is correct
      if (vm.xferClient && vm.xferClient.xferDetails && vm.xferClient.xferDetails.pathMapping) {
        for (var i = 0; i < vm.xferClient.xferDetails.pathMapping.length; i++) {
          if (vm.xferClient.xferDetails.pathMapping[i].destination && vm.xferClient.xferDetails.pathMapping[i].destination.id !== 0 && (vm.xferClient.xferDetails.pathMapping[i].AUDIO === '' || vm.xferClient.xferDetails.pathMapping[i].SD === '' || vm.xferClient.xferDetails.pathMapping[i].HD === '')) {
            NotificationService.showNotificationToast('Destination-specific delivery folders must have all paths set');
            return;
          }
        }
      }

      $scope.xferclientDetailForm.$setPristine();
      var transportType = '';

      switch (vm.xferClient.xferClientTransportType) {
        case 'FTP':
          transportType = 'FTP';
          break;

        case 'ASPERA':
          transportType = 'Aspera';
          break;

        case 'XFER':
          transportType = 'Xfer';
          break;

        default:
          transportType = 'Xfer';
      }

      XferClientResourceFactory.save({
        id: vm.xferClient.id
      }, vm.xferClient, function () {
        NotificationService.showNotificationToast('Saved ' + transportType + ' Client successfully!');
        $state.go('xferclientDetail', {
          id: vm.xferClient.id
        }, {
          reload: true
        });
      }, function (err) {
        NotificationService.showNotificationToast('Failed to save the ' + transportType + ' Client', err.data);
      });
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {
      enumService.getEnum('Spot', 'spotMetadataTransform').then(function (transforms) {
        vm.spotMetadataTransformList = transforms;
      });
      XferClientResourceFactory.get({
        id: $stateParams.id
      }, function (client) {
        vm.xferClient = client;
        vm.exportUrl = client.exportUrl;
        vm.originalXferClient = angular.copy(client);
        vm.xferClient.xferDetails.interval = client.xferDetails.interval && client.xferDetails.interval !== 'undefined' ? parseInt(client.xferDetails.interval, 10) : 60;
        vm.xferClient.xferDetails.maxConcurrent = client.xferDetails.maxConcurrent && client.xferDetails.maxConcurrent !== 'undefined' ? parseInt(client.xferDetails.maxConcurrent, 10) : 4;
        vm.xferClient.xferDetails.sourceFolder = client.xferDetails.sourceFolder ? client.xferDetails.sourceFolder : 'undefined';
        vm.xferClient.xferDetails.maxRetries = client.xferDetails.maxRetries && client.xferDetails.maxRetries !== 'undefined' ? parseInt(client.xferDetails.maxRetries, 10) : 2;
        $scope.xferclientDetailForm.$setPristine();
      });
      $timeout(function () {
        $rootScope.xferclientDetailForm = $scope.xferclientDetailForm;
        vm.xferclientDetailForm = $rootScope.xferclientDetailForm;
      });
    }
  }]);
})();