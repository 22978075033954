/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.settings').controller('XferclientDetailListController', ['$scope', '$state', '$stateParams', '$timeout', 'XferClientResourceFactory', 'loginService', function ($scope, $state, $stateParams, $timeout, XferClientResourceFactory, loginService) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this; // Store the search params in memory as we go

    var SearchParams = {};

    var _DynamicItems = function _DynamicItems() {
      this.reloadPages_();
    };

    _DynamicItems.prototype.getItemAtIndex = function (index, lazy) {
      var pageNumber = Math.floor(index / this.PAGE_SIZE);
      var page = this.loadedPages[pageNumber];
      lazy = lazy === undefined ? false : lazy;

      if (page) {
        return page[index % this.PAGE_SIZE];
      } else if (page !== null) {
        if (!lazy) {
          this.fetchPage_(pageNumber);
        }
      }
    };

    _DynamicItems.prototype.getLength = function () {
      return this.numItems;
    };

    _DynamicItems.prototype.setItemAtIndex = function (index, item) {
      var pageNumber = Math.floor(index / this.PAGE_SIZE);
      var page = this.loadedPages[pageNumber]; // Only update the screen if the spot is currently loaded

      if (page) {
        page[index % this.PAGE_SIZE] = item;
      }
    };

    _DynamicItems.prototype.fetchPage_ = function (pageNumber) {
      var self = this;
      vm.searching = true; // Set the page to null so we know it is already being fetched.

      self.loadedPages[pageNumber] = null;
      var pageOffset = pageNumber * self.PAGE_SIZE;
      var sortField = $stateParams.sortField ? $stateParams.sortField : 'xfer_client_id';
      var sortDirection = $stateParams.sortDirection ? $stateParams.sortDirection : 'DESC';
      $timeout(function () {
        // Filter out blank values, since we don't want to search by those
        for (var prop in SearchParams) {
          if (SearchParams.hasOwnProperty(prop)) {
            SearchParams[prop] = SearchParams[prop] === '' || SearchParams[prop] === null ? undefined : SearchParams[prop];
          }
        }

        SearchParams.offset = pageOffset;
        SearchParams.limit = self.PAGE_SIZE;
        SearchParams.sortField = sortField;
        SearchParams.sortDirection = sortDirection;
        SearchParams.accountId = vm.sessionData.accountId;
        XferClientResourceFactory.getAll(SearchParams, {}, function success(items) {
          items = items.data;
          self.loadedPages[pageNumber] = items.rows;
          self.numItems = items.count;
          vm.searching = false;
        }, function failure(err) {
          console.log(err);
        });
      });
    };

    _DynamicItems.prototype.reloadPages_ = function () {
      /**
       * @type {!Object<?Array>} Data pages, keyed by page number (0-index).
       */
      this.loadedPages = {};
      /** @type {number} Total number of items. */

      this.numItems = 0;
      /** @const {number} Number of items to fetch per request. */

      this.PAGE_SIZE = 50;
      this.getItemAtIndex(0);
    };
    /* BINDABLE : DATA */


    vm.currentId = null;
    vm.sessionData = loginService.getSessionData();
    vm.dynamicItems = $stateParams.dynamicItems ? $stateParams.dynamicItems : new _DynamicItems();
    vm.sortObj = {
      field: $stateParams.sortField ? $stateParams.sortField : 'xfer_client_id',
      direction: $stateParams.sortDirection ? $stateParams.sortDirection : 'ASC'
    };
    /* BINDABLE : METHODS */

    vm.backToSearch = backToSearch;
    vm.openDetail = openDetail;
    /* EVENTS */
    //Register any event listeners

    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
      // Wait for the current digest cycle to finish, so the grid will display properly
      $timeout(function () {
        // Utilize parameters
        SearchParams = toParams;
        vm.currentId = toParams.id;
      });
    });
    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function backToSearch() {
      $state.go('xferclients', {
        dynamicItems: vm.dynamicItems
      }, {
        notify: true
      });
    }

    function openDetail(row) {
      $state.go('xferclientDetail', {
        id: row.id,
        dynamicItems: vm.dynamicItems
      });
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {}
  }]);
})();