/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
var dependencies = ['comcast.common.authentication', 'comcast.common.communication', 'comcast.common.navigation', 'comcast.settings', 'ngMaterial', 'ngMessages', 'comcast.directives'];
var defaultName = angular.module('comcast.settings.xferclients', dependencies).config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state('xferclients', {
    // Specify params that this state can access from the URL
    url: '/xferclients?xferClientId&{offline:bool}&pageNumber',
    parent: 'settings',
    // Specify default values for state params
    params: {
      xferClientId: null,
      offline: false,
      pageNumber: null,
      sortField: null,
      sortDirection: null
    },
    reloadOnSearch: true,
    views: {
      'settingsContent@settings': {
        template: '<ui-view name="xferclientsContent"/>'
      },
      'settingsSearch@settings': {
        template: '<ui-view name="xferclientsSearch"/>'
      },
      'xferclientsContent@xferclients': {
        template: require('./xferclients-template.html'),
        controller: 'XferclientsController',
        controllerAs: 'vm'
      },
      'xferclientsSearch@xferclients': {
        template: require('./xferclientsSearch-template.html'),
        controller: 'XferclientsSearchController',
        controllerAs: 'vm'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  }).state('xferclientDetail', {
    url: '/:id',
    parent: 'xferclients',
    params: {
      gridData: null,
      pageData: null,
      dynamicItems: null
    },
    views: {
      xferclientsContent: {
        template: require('./xferclientDetail-template.html'),
        controller: 'XferclientDetailController',
        controllerAs: 'vm'
      },
      xferclientsSearch: {
        template: require('./xferclientDetailList-template.html'),
        controller: 'XferclientDetailListController',
        controllerAs: 'vm'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  });
}]).name;
export { defaultName as default, dependencies };